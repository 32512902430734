<template>
  <v-container fluid>
    <page-header title="推送通知"></page-header>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-text class="my-5">
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" md="6">
                    <form-select
                      label="推送目標"
                      :fieldValue.sync="formData.target"
                      required
                      :options="targetOptions"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <form-date-picker label="發送日期" :fieldValue.sync="formData.push_date" required />
              </v-col>
              <v-col cols="12" md="6">
                <form-time-picker label="發送時間" :fieldValue.sync="formData.push_time" required />
              </v-col>
              <v-col cols="12" md="6">
                <form-input label="通知標題" :fieldValue.sync="formData.title" required />
              </v-col>
              <v-col cols="12" md="6">
                <form-input label="通知內容" :fieldValue.sync="formData.content" required />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">發送</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormDatePicker from '@/components/form/FormDatePicker.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormTimePicker from '@/components/form/FormTimePicker.vue';
import FormSelect from '@/components/form/FormSelect.vue';
export default {
  name: 'Notification',
  components: {
    FormDatePicker,
    FormInput,
    FormTimePicker,
    FormSelect
  },
  data: () => ({
    formData: {
      target: '',
      push_date: '',
      push_time: '',
      title: '',
      content: '',
    },
    targetOptions: [
      { text: '所有人', value: 'all' },
      { text: '學生', value: 'student' },
      { text: '導師', value: 'tutor' },
    ],
  }),
  methods: {
    ...mapActions(['setAlert']),

    async save() {
        if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }
    },
  },

  async mounted() { },
}
</script>
